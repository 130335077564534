import { ActionTypes } from 'Actions/actions';
import type { CategoriesActionTypes } from 'ReduxTypes/categories';
import type { CategoryFeedActionTypes } from 'ReduxTypes/categoryFeed';

export const requestCategories = (cityCode: string): CategoriesActionTypes => ({
	type: ActionTypes.REQUEST_CATEGORIES_AND_SUBCATEGORIES,
	cityCode,
});

export const receiveCategories = (
	cityCode: string,
	data: object,
	url: string,
): CategoriesActionTypes => ({
	type: ActionTypes.RECEIVE_CATEGORIES_AND_SUBCATEGORIES,
	cityCode,
	data,
	url,
});

export const receiveCategorySections = (
	sections: object,
	city: object,
): CategoryFeedActionTypes => ({
	type: ActionTypes.RECEIVE_CATEGORY_SECTIONS,
	payload: { sections, city },
});

export const receiveSubcategoryFiltersByCategoryId = (payload: {
	subCategories: Array<object>;
	categoryId: number;
	useCollectionFilter?: boolean;
}) => ({
	type: ActionTypes.RECEIVE_SUBCATEGORY_FILTERS_BY_CATEGORY,
	payload,
});
